import {useMediaQuery} from "react-responsive";
import {media} from "../constants/breakpoints";

export const useResponsive = ()=> {
    const isMobile = useMediaQuery({ query: media.xs });
    const isTablet = useMediaQuery({ query: media.md });
    const isDesktop = useMediaQuery({ query: media.xl });
    const isBigScreen = useMediaQuery({ query: media.xxl });

    return {
        isMobile,
        isTablet,
        isDesktop,
        isBigScreen,
        useMediaQuery,
    };
};

import {
    mdiChartAreaspline,
    mdiListBox, mdiLogout,
    mdiMapMarkerDistance,
    mdiMoped,
    mdiShieldAccountOutline,
    mdiSilverwareForkKnife
} from "@mdi/js";

export const menuItems = [
    // {
    //   label: "Strona główna",
    //   iconPath: mdiViewDashboard,
    //   link: "/home"
    // },
    {
        label: "Kierowcy",
        iconPath: mdiMoped,
        link: "/kierowcy"
    },{
        label: "Restauracje",
        iconPath: mdiSilverwareForkKnife,
        link: "/restauracje"
    },{
        label: "Zlecenia",
        iconPath: mdiListBox,
        link: "/zlecenia"
    }
]

export const superAdminMenuItems = [
    {
        label: "Oddziały",
        iconPath: mdiMapMarkerDistance,
        link: "/oddzialy"
    },
    {
        label: "Administratorzy",
        iconPath: mdiShieldAccountOutline,
        link: "/administratorzy"
    },
]
export const bottomMenuItems = [
    {
        label: "Wyloguj",
        iconPath: mdiLogout,
        link: "/logout"
    }
]
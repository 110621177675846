import {CreditCardOutlined, DollarOutlined} from "@ant-design/icons";
import {COLORS} from "../../../../constants/colors";
import {Tag} from "antd";
import formatAmount from "../../../../functions/formatAmount";

const _card = "card"
const _cash = "cash"
const AmountInfo = ({paymentType, amount}) => {
    return (
        <div style={{position: "relative", display:"inline-block"}}>
            <Tag icon={paymentType === _card ? <CreditCardOutlined /> : paymentType === _cash ? <DollarOutlined /> : null} color={paymentType === _card ? "geekblue" : paymentType === _cash ? "orange": "default" }>
                {formatAmount(amount)} zł
            </Tag>
            <div style={{position: "absolute", bottom:-15, left: 2, fontSize: 10, color: "#797979"}}>{paymentType === _card ? "Karta" : paymentType === _cash ? "Gotówka" : ""}</div>
        </div>

    )
}
export default AmountInfo
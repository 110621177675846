import {Button, Card, Table, Tag, DatePicker, Spin, Badge, Modal} from "antd";
import {useEffect, useState} from "react";
import formatPhoneNumber from "../../functions/formatPhoneNumber";
import DriverForm from "./components/DriverForm/DriverForm";
import {EnvironmentOutlined, PlusOutlined} from "@ant-design/icons";
import DriverPosition from "./components/DriverPosition/DriverPosition";
import OrderInfo from "./components/OrderInfo/OrderInfo";
import {API_getDrivers} from "../../services/api/apiCalls";
import useApi from "../../hooks/useApi";
import {APIProvider, Map, Marker} from '@vis.gl/react-google-maps';
import DriversMap from "./components/DriversMap/DriversMap";

function Kierowcy ()  {
    const [isFormVisible, setFormVisible] = useState(false);
    const [selectedDriver, setSelectedDriver] = useState(null);
    const [formType, setFormType] = useState("add");

    const [isOrderInfoVisible, setOrderInfoVisible] = useState(false);
    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            width: 50,
        },
        {
            title: "IMIĘ",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "NAZWISKO",
            dataIndex: "lastname",
            key: "lastname"
        },
        {
            title: "LOGIN",
            key: "login",
            dataIndex: "login",
        },
        {
            title: "TELEFON",
            key: "phone",
            dataIndex: "phone",
            render: (phone) => formatPhoneNumber(phone)
        },
        {
            title: "STATUS",
            key: "status",
            align: "center",
            dataIndex: "status",
            filters:[
                {
                    text: "Pracuje",
                    value: true
                }  ,
                {
                    text: "Nie pracuje",
                    value: false
                }
            ],
            onFilter:(value, record) => record.status === value,
            render: (status) =>   <Tag color={status? "green" : "gainsboro"}>{status ? "PRACUJE" : "NIE PRACUJE" }</Tag>
        },
        {
            title: "ZLECENIE",
            key: "order",
            align: "center",
            render: (record) => {
                if(record.order>0) return <Button onClick={() => handleOpenOrder(record.order)} disabled={!record.order} size="small">{record.order || "-"}</Button>
                else return "-"
            }
        },
        {
            title: "AKCJE",
            key: "akcje",
            align: "center",
            render: (record) => {
                return <div>
                    <Button size="small" onClick={() => handleOpenEditForm(record)}>Edytuj</Button>
                </div>
            }
        }
    ];

    const getDriversApi = useApi(API_getDrivers,[], "Nie udało się pobrać kierowców.",true,true)

    useEffect(() => {
        getDriversApi.call();
    }, [])


    const handleOpenOrder = (orderId) => {       setOrderInfoVisible(true);
        setSelectedOrderId(orderId);
    }
    const handleCloseOrder = () => {
        setOrderInfoVisible(false);
    }
    const handleOpenAddForm = () => {
        setFormVisible(true);
        setFormType("add");
        setSelectedDriver(null);
    }
    const handleOpenEditForm = (driver) => {
        setFormVisible(true);
        setFormType("edit");
        setSelectedDriver(driver)
    }
    const handleCloseForm = () => {
        setFormVisible(false);
    }
    const handleSubmit = () => {
        setFormVisible(false);
        getDriversApi.call();
    }

    const [openModal, setOpenModal] = useState(false);
    return (
        <>
            <Modal title="Kierowcy na mapie"
                   width={"80%"}
                   open={openModal}
                   footer={null}
                   onCancel={() => setOpenModal(false)}>
                <DriversMap drivers={getDriversApi.data}/>
            </Modal>
            <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title={
                <>
                    Kierowcy
                    <Badge overflowCount={9999} offset={[5,-5]} count={getDriversApi?.data.length} color={"#1677ff"}></Badge>
                </>
            }
            extra={
                <>
                    <Spin spinning={getDriversApi.silentLoading} style={{marginRight:10}}/>
                    <Button style={{marginRight:10}} onClick={() => setOpenModal(true)} icon={<EnvironmentOutlined />}>Mapa</Button>
                    <Button onClick={handleOpenAddForm} icon={<PlusOutlined />}>Dodaj kierowcę</Button>
                </>
            }
        >
            <div className="table-responsive">
                <Table loading={getDriversApi.loading}
                       rowKey={(record) => record.id+"driver"}
                    columns={columns}
                    dataSource={getDriversApi.data}
                    pagination={false}
                    className="ant-border-space"
                />
            </div>
            </Card>
            <DriverForm formType={formType} visible={isFormVisible} driver={selectedDriver} onClose={handleCloseForm} onSubmit={handleSubmit}/>
            <OrderInfo visible={isOrderInfoVisible} orderId={selectedOrderId} onClose={handleCloseOrder}/>
        </>

    )
}
export default Kierowcy
import {createAction, createSlice} from "@reduxjs/toolkit";
import {persistCurrentDistrictId, readCurrentDistrictId} from "../../services/localStorage.service";


const initialState = {
    currentDistrictId: readCurrentDistrictId(),
    currentDistrict: null,
    districts: []
};

export const setCurrentDistrictId = createAction(
    'districts/setCurrentDistrictId',
    (districtId) => {
        persistCurrentDistrictId(districtId)
        return {
            payload: districtId
        }
    }
)

export const setDistricts = createAction(
    'districts/setDistricts',
    (districts) => {
        return {
            payload: districts
        }
    }
)

export const districtsSlice = createSlice({
    name: 'districts',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(setCurrentDistrictId, (state, action) => {
                state.currentDistrictId =  action.payload;
                state.currentDistrict = state.districts ? state.districts.find(district => district.value === action.payload) || state.districts[0] || null : null
        })
            .addCase(setDistricts, (state, action) => {
                state.districts = action.payload;
                state.currentDistrict = action.payload ? action.payload.find(district => district.value === readCurrentDistrictId()) || action.payload[0] || null : null
                state.currentDistrictId = state.currentDistrict?.value

            })
    }
})
export default districtsSlice.reducer;
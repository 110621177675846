import * as S from './FilterCard.styled'
import Icon from "@mdi/react";
const FilterCard = ({title, iconPath, children}) => {
    return (
        <S.MainCard>

            <S.Title>
                {iconPath ?
                    <Icon style={{marginRight:4}} color={"#1677ff"} path={iconPath} size={0.6} />
                    :
                    ""
                }
                {title}
            </S.Title>
            {children}
        </S.MainCard>
    )
}
export default FilterCard
import {DatePicker, Space} from "antd";
import DateFilter from "./components/DateFilter/DateFilter";
import {useEffect, useState} from "react";
import DriverFilter from "./components/DriverFilter/DriverFilter";
import RestaurantFilter from "./components/RestaurantFilter/RestaurantFilter";
import StatusFilter from "./components/StatusFilter/StatusFilter";
import PaymentTypeFilter from "./components/PaymentTypeFilter/PaymentTypeFilter";


const Filters = ({handleFiltersChange = (filters) => {}, defaultFilters}) => {
    const [filters, setFilters] = useState(defaultFilters)

    const handleFilter = (filtersObject) => {
        setFilters((prevState) => ({
                ...prevState,
                ...filtersObject
            }))
    }
    useEffect(() => {
        handleFiltersChange(filters)
    },[filters])
    return (
        <Space wrap direction="horizontal" size={16} style={{gap:0}}>
            <DateFilter onChange={handleFilter} defaultFilters={defaultFilters}/>
            <RestaurantFilter onChange={handleFilter}/>
            <DriverFilter onChange={handleFilter}/>
            <PaymentTypeFilter onChange={handleFilter}/>
            <StatusFilter onChange={handleFilter} defaultFilters={defaultFilters}/>
        </Space>
    )
}
export default Filters
import styled from "styled-components";

export const SidenavInner = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
`
export const SidenavLogo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
`
export default function stringToHslColor(str, s = 60, l = 65) {
    if(!str) return;
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    let h = hash % 360;
    return 'hsl('+h+', '+s+'%, '+l+'%)';
}

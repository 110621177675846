import {Navigate} from "react-router-dom";
import {readRefreshToken} from "../../services/localStorage.service";
import MainLayout from "./MainLayout";

function ProtectedLayout() {
  if(!readRefreshToken() ) return <Navigate to="/login"/>

  return <MainLayout/>
}

export default ProtectedLayout;

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import {Provider} from 'react-redux';
import {store} from "./redux/store";
import {ConfigProvider} from "antd";
import pl_PL from "antd/lib/locale/pl_PL"

ReactDOM.render(
    <ConfigProvider locale={pl_PL}>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </ConfigProvider>

,
  document.getElementById("root"),
);

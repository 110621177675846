import {Menu} from "antd";
import {NavLink, useLocation} from "react-router-dom";
import Icon from "@mdi/react";
import {CustomNavLink} from "./SidenavMenuItem.styles";

const SidenavMenuItem = ({menuItem}) => {
    const roles = {
        SUPERADMIN: "",
        ADMIN: "",
        DRIVER: "",
        REST: ""
    }
    const { pathname } = useLocation();
    return (
        <Menu.Item key={menuItem.link}>
            <CustomNavLink to={menuItem.link}>
            <span
                className="icon"
                style={{
                    background: pathname === menuItem.link ? "#1890ff" : "",
                }}
            >
              <Icon color={pathname === menuItem.link ? "white" : "#1890ff"} path={menuItem.iconPath} size={0.9} />
            </span>
                <span className="label">{menuItem.label}</span>
            </CustomNavLink>

        </Menu.Item>
    )
}
export default SidenavMenuItem
import {Navigate, Outlet} from "react-router-dom";
import {useSelector} from "react-redux";

function ProtectedLayoutSuperAdmin() {
  const user = useSelector(state => state.user.data);
  if(user.role !== "SuperAdministrator") return <Navigate to="/"/>

  return <>
  <Outlet/>
  </>
}

export default ProtectedLayoutSuperAdmin;

import {Avatar} from "antd";
import stringToHslColor from "../../../../functions/stringToHslColor";
import aliasFromUsername from "../../../../functions/aliasFromUsername";
import {useSelector} from "react-redux";
import {UserInfoBox, UserInfoRole, UserInfoUsername, UserInfoUsernameBox} from "./UserInfo.styles";

export default function UserInfo(){
    const user = useSelector(state => state.user.data);
    if(!user) return <></>
    return (
        <UserInfoBox>
            <Avatar size={35} style={{backgroundColor: stringToHslColor(user.userName)}}>{aliasFromUsername(user.userName)}</Avatar>
            <UserInfoUsernameBox>
                <UserInfoUsername>{user.userName}</UserInfoUsername>
                <UserInfoRole>{user.role}</UserInfoRole>
            </UserInfoUsernameBox>
        </UserInfoBox>
    )
}
import {useEffect, useState} from "react";
import {Button, Popconfirm} from "antd";
import {DownloadOutlined, FileExcelOutlined} from "@ant-design/icons";
import useApi from "../../../hooks/useApi";
import {API_getOrders} from "../../../services/api/apiCalls";
import moment from "moment";
import {excelExportReport} from "../../../services/files/excelService";

function ExportExcelButton({queryObject}) {
    const [readyToDownload, setReadyToDownload] = useState(false);
    const [exportClicked, setExportClicked] = useState(false);
    const getAllOrders = useApi(API_getOrders,{}, "Nie udało się pobrać zamówień.",false,true)
    useEffect(() => {
        if(readyToDownload) {
            excelExportReport(getAllOrders.data.data, formatFileName())
            setReadyToDownload(false);
            setExportClicked(false);
        }
    },[readyToDownload])
    useEffect(() => {
        if(getAllOrders.loaded && exportClicked)
            setReadyToDownload(true);
    },[getAllOrders.loaded])
    const fetchData = (withFilters = true) => {
        setExportClicked(true);
        if(withFilters)
            getAllOrders.call({...queryObject, pagination:{page:1,pageSize:99999}},true)
        else
            getAllOrders.call({filters:{dateFrom: queryObject.filters.dateFrom, dateTo: queryObject.filters.dateTo}, pagination:{page:1,pageSize:99999}},true)
    }
    const formatFileName = () => {
        let filters = ""
        if(queryObject.filters.dateFrom && queryObject.filters.dateTo){
            const dateFrom = moment(new Date(queryObject.filters.dateFrom)).format("DD-MM-YYYY")
            const dateTo = moment(new Date(queryObject.filters.dateTo)).format("DD-MM-YYYY")
            filters = `(${dateFrom}_${dateTo})_`
        }
        return `Dostawy24Raport_${filters}${moment(new Date()).format("DD-MM-YYYY_hh-mm")}`
    }

    return (
        <>
            <Popconfirm
                title="Pobieranie raportu dla wybranego zakresu dat."
                onConfirm={() => fetchData(false)}
                onCancel={() => fetchData(true)}
                description={'Czy chcesz uwzględnić dodatkowo zaznaczone filtry?'}
                okText="Pobierz wszystko"
                cancelText="Pobierz z filtrami"
                icon={<DownloadOutlined style={{ color: '#1677ff' }}
                />}
            >
                <Button loading={getAllOrders.loading && exportClicked} icon={<FileExcelOutlined />} type="primary">Pobierz raport</Button>
            </Popconfirm>
        </>
    )
}
export default ExportExcelButton
import FilterCard from "../FilterCard/FilterCard";
import {Select} from "antd";
import {mdiCash} from "@mdi/js";
const PaymentTypeFilter = ({onChange}) => {
    const handleChange = (value, option) => {
        onChange({payMethod: value})
    }

    return (
        <FilterCard title="Płatność" iconPath={mdiCash}>
            <Select
                style={{width:150}}
                allowClear
                placeholder="Wybierz płatność"
                optionFilterProp="children"
                onChange={handleChange}
                options={[
                    {value: 'card', label: "Karta"},
                    {value: 'cash', label: "Gotówka"},
                    {value: '', label: "Brak"},
                ]}
            />
        </FilterCard>
    )
}
export default PaymentTypeFilter
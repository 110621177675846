import axios from "axios";
const baseURL =  process.env.REACT_APP_API_URL;
const apiService = axios.create({
    baseURL
})
apiService.setToken = (token) => {
    apiService.defaults.headers.common['userToken'] = token;
}
apiService.setDistrict = (district) => {
    apiService.district = district
}
export default apiService
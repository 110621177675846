import {Button, Card, Form, Input, notification,} from "antd";
import * as S from './Login.styled'
import logo from "../../assets/imgs/logo.png";
import React, {useState} from "react";
import {Content} from "antd/es/layout/layout";
import {API_login} from "../../services/api/apiCalls";
import {useDispatch} from "react-redux";
import {setUser} from "../../redux/slices/userSlice";
import {setDistricts} from "../../redux/slices/districtsSlice";
import {Navigate, useNavigate} from "react-router-dom";
import {persistSessionTime, readRefreshToken} from "../../services/localStorage.service";
const Login = () => {
    const [loading, setLoading] = useState(false);
    const [loginFormSucceed, setLoginFormSucceed] = useState(false)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onFinish = (values) => {
        setLoading(true);
        API_login(values.login, values.password)
            .then(({data}) => {
                setLoginFormSucceed(true);
                dispatch(setUser(data.userInfo, data.userToken, data.refreshToken));
                dispatch(setDistricts(data.districts));
                navigate('/');
                persistSessionTime();
            })
            .catch(() => {
                notification.error({message: "Wprowadzono błędne dane logowania"})
            })
            .finally(() => {setLoading(false)})
    };


    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    if(readRefreshToken() && !loginFormSucceed) return <Navigate to="/"/> // Navigate to main page if user is already logged in
    return (
        <S.LoginOuter>
            <Card
                headStyle={{textAlign:"center"}}
                title={"Panel administratora"}
                style={{ width: 400 }}
                bodyStyle={{display:"flex", flexDirection:"column", alignItems:"center", padding:"24px 40px"}}>

                <Content style={{alignSelf:"stretch"}}>

                    <Form
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        layout="vertical"
                    >
                        <Form.Item
                            label="Login"
                            name="login"
                            rules={[
                                {
                                    required: true,
                                    message: "Wprowadź swój login.",
                                },
                            ]}
                        >
                            <Input placeholder="Login" />
                        </Form.Item>

                        <Form.Item
                            label="Hasło"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: "Wprowadź swoje hasło.",
                                },
                            ]}
                        >
                            <Input type="password" placeholder="Hasło" />
                        </Form.Item>

                        <Form.Item>
                            <Button
                                loading={loading}
                                type="primary"
                                htmlType="submit"
                                style={{ width: "100%", marginTop: 5  }}
                            >
                                Zaloguj
                            </Button>
                        </Form.Item>
                    </Form>
                </Content>
                <S.LoginLogo src={logo} alt="" />
            </Card>

        </S.LoginOuter>

    )
}
export default Login
import {Drawer, Tag} from "antd";
import {useResponsive} from "../../../../hooks/useResponsive";

export default function OrderInfo({onClose,orderId, visible}){
    const {isTablet} = useResponsive();
    const handleClose = () => {
        onClose();
    }
    return (
        <Drawer
            title={<div>Podgląd zamówienia <Tag>{orderId}</Tag></div>}
            width={isTablet ? 450 : null}
            onClose={handleClose}
            placement="right"
            open={visible}
        >
           <h1>Dostępne już wkrótce!</h1>
        </Drawer>

    )
}
export const BREAKPOINTS = {
    xs: 360,
    sm: 568,
    md: 768,
    lg: 992,
    xl: 1280,
    xxl: 1920,
};

const getMedia = (breakpoint) => `(min-width: ${breakpoint}px)`;
export const media = {
    xs: getMedia(BREAKPOINTS.xs),
    sm: getMedia(BREAKPOINTS.sm),
    md: getMedia(BREAKPOINTS.md),
    lg: getMedia(BREAKPOINTS.lg),
    xl: getMedia(BREAKPOINTS.xl),
    xxl: getMedia(BREAKPOINTS.xxl),
};
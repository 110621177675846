import {useEffect, useState} from "react";
import useDistricts from "./useDistricts";
import {notification} from "antd";
import {useNavigate} from "react-router-dom";
import apiService from "../services/api/api.service";
import moment from "moment/moment";
import {getItemFromSecureLocalStorage, setItemToSecureLocalStorage} from "../services/localStorage.service";

const useApi = (apiCall, emptyData= null, errorMessage = "Nie udało się pobrać danych.",storeResponse= true, watchCurrentDistrict = false, lastUpdateTimer = false) => {
    const districts = useDistricts()
    const name = apiCall.name + "_" + districts?.currentDistrict?.value
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [silentLoading, setSilentLoading] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const [lastUpdate, setLastUpdate] = useState(null);
    const [lastUpdateEasyRead, setLastUpdateEasyRead] = useState(null);
    const [data, setData] = useState(JSON.parse(getItemFromSecureLocalStorage(name)) || null);
    const [error, setError] = useState("")
    const navigate = useNavigate();

    const [lastQuery, setLastQuery] = useState(null);
    const [lastForceShowLoading, setLastForceShowLoading] = useState(false);
    useEffect(()=>{
        let myInterval = lastUpdateTimer ? setInterval(() => {
            if(lastUpdate) setLastUpdateEasyRead( moment(new Date(lastUpdate)).fromNow())
        }, 30000) : null;
        return ()=> {
            clearInterval(myInterval);
        };
    });
    useEffect(() => {
        if(lastUpdate) setLastUpdateEasyRead( moment(new Date(lastUpdate)).fromNow())
    }, [lastUpdate])
    useEffect(() => {
        // check if its not first load and call query after district change
        if(!firstLoad && watchCurrentDistrict) {
            apiService.setDistrict(districts.currentDistrict.value)
            call(lastQuery, lastForceShowLoading)
        }
    }, [districts.currentDistrict])
    const call = (query = null, forceShowLoading = false) => {
        setLastQuery(query);
        setLastForceShowLoading(forceShowLoading);
        setLoaded(false);
        if(!data || forceShowLoading) setLoading(true);
        else setSilentLoading(true);
        apiCall(query)
            .then(({data}) => {
                setData(data)
                setLoaded(true);
                setLastUpdate(new Date());
                if(storeResponse) setItemToSecureLocalStorage(name, JSON.stringify(data))
            }).catch((err) => {
                if (err?.response?.status === 401) {
                    notification.error({message: "Brak autoryzacji, wymagane logowanie."});
                    navigate('/logout');
                }
                console.log(err)
            notification.error({message: "Nie udało się pobrać danych."});
                setError(errorMessage)
        }).finally(() => {
            setLoading(false)
            setSilentLoading(false)
            setFirstLoad(false)
        })
    }
    return {
        data: data || emptyData || [],
        loading,
        lastUpdate,
        lastUpdateEasyRead,
        silentLoading,
        error,
        loaded,
        call
    }
}
export default useApi
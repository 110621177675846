import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {removeUser} from "../../redux/slices/userSlice";
import {Navigate} from "react-router-dom";

const Logout = () => {
    const dispatch = useDispatch();

    useEffect( () => {
        dispatch(removeUser());
    }, [])
    return <Navigate to="/login"/>
}
export default Logout
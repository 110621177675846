import {Badge, Button, Card, Spin, Table} from "antd";
import {useEffect, useState} from "react";
import formatPhoneNumber from "../../functions/formatPhoneNumber";
import {EnvironmentOutlined, PlusOutlined} from "@ant-design/icons";
import RestaurantForm from "./RestaurantForm/RestaurantForm";
import useApi from "../../hooks/useApi";
import {API_getRestaurants} from "../../services/api/apiCalls";

const restaurantsData = [
    {
        name: "Koku Sushi",
        address: "Rynek 24, Ostrów Wielkopolski",
        login: "koku123",
        phone: "+48124556998",
    },
    {
        name: "Pizzeria flamingo",
        address: "Wolności 7B, Ostrów Wielkopolski",
        login: "koku123",
        phone: "+48124556998",
    },
]
function Restauracje ()  {
    const [isFormVisible, setFormVisible] = useState(false);
    const [selectedRestaurant, setSelectedRestaurant] = useState(null);
    const [formType, setFormType] = useState("add");

    const getRestaurantsApi = useApi(API_getRestaurants,[], "Nie udało się pobrać restauracji.",true,true)
    const columns = [
        {
            title: "NAZWA",
            dataIndex: "name",
            key: "name",
            render: (name) => <b>{name}</b>
        },
        {
            title: "ADRES",
            dataIndex: "address",
            key: "address",
            render: (address) => <div><Button style={{marginRight:10}} type="primary" size="small" onClick={() => window.open('https://www.google.com/maps?q='+address, '_blank', 'noreferrer')} icon={<EnvironmentOutlined />}></Button>{address}</div>
        },
        {
            title: "LOGIN",
            key: "login",
            dataIndex: "login",
        },
        {
            title: "TELEFON",
            key: "phone",
            dataIndex: "phone",
            render: (phone) => formatPhoneNumber(phone)
        },
        {
            title: "AKCJE",
            key: "akcje",
            align: "center",
            render: (record) => {
                return <div>
                    <Button size="small" onClick={() => handleOpenEditForm(record)}>Edytuj</Button>
                </div>
            }
        }
    ];
    useEffect(() => {
        getRestaurantsApi.call()
    }, [])
    const handleOpenAddForm = () => {
        setFormVisible(true);
        setFormType("add");
        setSelectedRestaurant(null);
    }
    const handleOpenEditForm = (restaurant) => {
        setFormVisible(true);
        setFormType("edit");
        setSelectedRestaurant(restaurant)
    }
    const handleCloseForm = () => {
        setFormVisible(false);
    }
    const handleSubmit = () => {
        getRestaurantsApi.call();
        setFormVisible(false);
    }
    return (
        <>
            <Card
                bordered={false}
                className="criclebox tablespace mb-24"
                title={
                    <>
                        Restauracje
                        <Badge overflowCount={9999} offset={[5,-5]} count={getRestaurantsApi?.data.length} color={"#1677ff"}></Badge>
                    </>
                }
                extra={
                    <>
                        <Spin spinning={getRestaurantsApi.silentLoading} style={{marginRight:10}}/>
                        <Button onClick={handleOpenAddForm} icon={<PlusOutlined />}>Dodaj restaurację</Button>
                    </>
                }
            >
                <div className="table-responsive">
                    <Table
                        rowKey={(record) => record.id+"rest"}
                        columns={columns}
                        loading={getRestaurantsApi.loading}
                        dataSource={getRestaurantsApi.data}
                        pagination={false}
                        className="ant-border-space"
                    />
                </div>
            </Card>
            <RestaurantForm onClose={handleCloseForm} onSubmit={handleSubmit} restaurant={selectedRestaurant} visible={isFormVisible} formType={formType}/>
        </>

    )
}
export default Restauracje
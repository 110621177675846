import {APIProvider, Map, Marker} from "@vis.gl/react-google-maps";
import {useEffect, useState} from "react";
import formatStringToCords from "../../../../functions/formatStringToCords";

const OddzialyMap = ({onPlaceSelect, defaultMarkerPosition, formVisible}) => {
    const [markerPosition, setMarkerPosition] = useState(formatStringToCords(defaultMarkerPosition))
    useEffect(() => {
        setMarkerPosition(formatStringToCords(defaultMarkerPosition))
    }, [defaultMarkerPosition]);


    const handleMapSelection = (event) => {
        const lat = event.detail.latLng.lat;
        const lon = event.detail.latLng.lng;
        setMarkerPosition({lat, lon})
        onPlaceSelect(`${lat}, ${lon}`)
    }
    if(!formVisible) return null;
    return (
        <APIProvider  apiKey={process.env.REACT_APP_MAPS_API_KEY} >
            <div style={{height:"300px", marginTop:20, marginBottom:20}}>
                <Map mapId={process.env.REACT_APP_MAP_ID} onClick={handleMapSelection} center={{lat: markerPosition.lat, lng: markerPosition.lon}} zoom={defaultMarkerPosition? 12: 5} streetViewControl={false} mapTypeControl={false} fullscreenControl={false}>
                    {defaultMarkerPosition ?
                        <Marker position={{lat: markerPosition.lat, lng: markerPosition.lon}} />
                        : null
                    }
                </Map>
            </div>

        </APIProvider>
    )
}
export default OddzialyMap
import styled from "styled-components";
import {NavLink} from "react-router-dom";
import {COLORS} from "../../../../constants/colors";
export const CustomNavLink = styled(NavLink)`
  padding: 10px 16px;
  color: #141414;
  border-radius: 8px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  &.active{
    background-color: ${COLORS.primary};
    color: white;
    box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
    font-weight: 600;
  }
`
import {Button} from "antd";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {persistSessionTime, readSessionTime} from "../../../services/localStorage.service";
import moment from "moment";
import {SESSION_PARAMS} from "../../../constants/sessionParams";
import isSessionValid from "../../../functions/isSessionValid";

const SessionTimer = () => {
    const {initialMinute,initialSeconds, warningMinutes, infoMinutes } = SESSION_PARAMS
    const [ minutes, setMinutes ] = useState(initialMinute);
    const [seconds, setSeconds ] =  useState(initialSeconds);
    const [hovered,setHovered] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        checkSessionTime()
    }, [])

    const checkSessionTime = (saveToLocal = true) => {
        if(!isSessionValid()){
            navigate('/logout');
        }else if(saveToLocal){
            persistSessionTime();
        }
    }
    const handleClickDocument = () => {
        resetTimer()
    }
    useEffect(()=>{
        let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    navigate('/logout');
                    clearInterval(myInterval)
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
            checkSessionTime(false)
        }, 1000)
        return ()=> {
            clearInterval(myInterval);
        };
    });
    useEffect(() => {
        document.addEventListener('click', handleClickDocument);
        return () => {
            document.removeEventListener('click', handleClickDocument);
        }
    }, [])
    const handleMouseEnter = () => {
        setHovered(true)
    }
    const handleMouseLeve = () => {
        setHovered(false)
    }
    const resetTimer = () => {
        setMinutes(initialMinute);
        setSeconds(initialSeconds);
        persistSessionTime();
    }
    const handleClick = () => {
        setHovered(false)
        resetTimer()
    }
    return (
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeve}>
            <Button danger={minutes <= warningMinutes} type={minutes <= infoMinutes ? "primary" : "link"} style={{width:140}} onClick={handleClick} color="blue">
                {hovered ?
                    "Przedłuż sesję" :
                    minutes < warningMinutes ?
                    `Czas sesji ${String("0" + minutes).slice(-2)}:${String("0" + seconds).slice(-2)}`
                        :
                        `Czas sesji ${minutes} min`
                }
            </Button>
        </div>

    )
}
export default SessionTimer
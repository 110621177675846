import {AdvancedMarker, APIProvider, Map, Marker, Pin} from "@vis.gl/react-google-maps";
import {useEffect, useState} from "react";
import useApi from "../../../../hooks/useApi";
import {API_getDriversPosition, API_getRegion} from "../../../../services/api/apiCalls";
import DriverInfo from "../../../Zlecenia/components/DriverInfo/DriverInfo";
import {Avatar, Switch} from "antd";
import {CarOutlined} from "@ant-design/icons";

const DriversPosition = ({drivers}) => {
    const [positions, setPositions] = useState({});
    const getDriversPositionApi = useApi(API_getDriversPosition,[],"Nie można pobrać pozycji kierowców.", false,true,false);
    useEffect(() => {
        let myInterval = setInterval(() => {
            getDriversPositionApi.call();
        }, 5000)
        return ()=> {
            clearInterval(myInterval);
        };
    }, [])
    useEffect(() => {
        let output = {};
        if(!Array.isArray(getDriversPositionApi?.data)) return;
        getDriversPositionApi.data.forEach(driverPosition => {
            output[driverPosition.id] = {
                lat: driverPosition.lat,
                lon: driverPosition.lon
            }
        })
        setPositions(output)
    }, [JSON.stringify(getDriversPositionApi.data)])

    return (
        <>
            {drivers.map(driver => {
                    const lat = parseFloat((positions[driver.id]?.lat || driver.lat).replace(",","."))
                    const lon = parseFloat((positions[driver.id]?.lon || driver.lon).replace(",","."))
                if(!lat || !lon) return null;

                return <AdvancedMarker key={"pin_"+driver.id} position={{lat: lat, lng: lon}}>
                    <div style={{backgroundColor: "white", padding: "4px 8px 4px 4px", borderRadius:18, boxShadow: "1px 2px 6px #00000017", marginBottom:24}}>
                        <DriverInfo driver={driver}/>
                        <Avatar style={{position: "absolute", backgroundColor: driver.status ? "#1677ff" : "", bottom: "0", left: "50%", transform: 'translateX(-50%)'}} size={22} icon={<CarOutlined />}/>
                    </div>
                </AdvancedMarker>
                }

            )}
        </>
    )
}
const DriversMap = ({drivers}) => {
    const [center, setCenter] = useState({lat: 0, lon:0})
    const getDistrictApi = useApi(API_getRegion,{},"Nie udało się pobrać informacji o regionie.", true,true,false);
    const [showNotWorking, setShowNotWorking] = useState(false);
    useEffect(() => {
        getDistrictApi.call();
    }, [])
    useEffect(() => {
        const centerCordsArr = getDistrictApi?.data?.center?.split(",")
        if(centerCordsArr){
            setCenter({
                lat: parseFloat(centerCordsArr[0]),
                lon: parseFloat(centerCordsArr[1])
            })
        }
    },[JSON.stringify(getDistrictApi.data)])
    const switchChange = (checked) => {
        setShowNotWorking(checked)
    }
    return(
            <APIProvider apiKey={process.env.REACT_APP_MAPS_API_KEY} >
                <div style={{height:"70vh", minHeight:200, marginTop:20, position:"relative"}}>
                    <div style={{position:"absolute", top:0, right:0, zIndex:999, display:"flex",justifyContent:"center", padding: "10px 15px", borderRadius: "0 0 0 10px", backgroundColor:"white", border: "1px solid gainsboro"}}>
                        <span style={{marginRight:10, fontWeight:600}}>Pokaż niepracujących</span>
                        <Switch onChange={switchChange} />
                    </div>
                    <Map mapId={process.env.REACT_APP_MAP_ID} center={{lat: center.lat, lng: center.lon}} zoom={13} streetViewControl={false} mapTypeControl={false} fullscreenControl={false}>
                        <DriversPosition drivers={drivers.filter(driver => {
                            return driver.status === !showNotWorking || showNotWorking === true
                        })}/>
                    </Map>
                </div>

            </APIProvider>
    )

}
export default DriversMap
import styled from "styled-components";
import {COLORS} from "../../../../constants/colors";

export const DriverInfoBoxOuter = styled.div`
  display: flex;
`
export const DriverInfoBox = styled.div`
  //cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: .18s color;
/*  &:hover{
    color: ${COLORS.primary}
  }*/
`
export const DriverInfoName = styled.div`
  margin-left: 5px;
  white-space: break-spaces;
`

import {Menu, Select} from "antd";
import logo from "../../../assets/imgs/logo.png";
import SidenavMenuItem from "./SidenavMenuItem/SidenavMenuItem";
import UserInfo from "./UserInfo/UserInfo";
import * as S from "./Sidenav.styles";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentDistrictId} from "../../../redux/slices/districtsSlice";
import useDistricts from "../../../hooks/useDistricts";
import {Navigate, useLocation} from "react-router-dom";
import {bottomMenuItems, menuItems, superAdminMenuItems} from "../menuItems";


function Sidenav({ color }) {
    const dispatch = useDispatch();
    const districts = useDistricts();
    const { pathname } = useLocation();
    const user = useSelector(state => state.user.data);
    const handleSelect =(id) => {
        dispatch(setCurrentDistrictId(id))
    }
  return (
      <S.SidenavInner>
          <div>
              <UserInfo/>
              <hr />
              {
                  superAdminMenuItems.some(({link}) => link === pathname) ?
                      <Select
                          key={"sa"}
                          disabled
                          defaultValue="Wszystkie oddziały"
                          style={{ width: "100%" }}
                      />
                      :
                      <Select
                          key={"a"}
                          defaultValue={districts.currentDistrict}
                          style={{ width: "100%" }}
                          onChange={handleSelect}
                          options={districts.districts}
                      />
              }

              <hr />
              <Menu key="mainMenu" theme="light" mode="inline">
                  {menuItems.map((item) => SidenavMenuItem({menuItem: item})
                  )}
              </Menu>
              {user.role === "SuperAdministrator" ?
              <>
                  <hr />
                  <Menu key="superAdminMenu" theme="light" mode="inline">
                      <Menu.Item className="menu-item-header" key={"title_1"}>
                          Zarządzanie
                      </Menu.Item>
                      {superAdminMenuItems.map((item) => SidenavMenuItem({menuItem: item})
                      )}
                  </Menu>
              </>
                  :
                  ""
              }

          </div>
          <S.SidenavLogo>
              <Menu key="bottomMenu" theme="light" mode="inline">
                  {bottomMenuItems.map((item) => SidenavMenuItem({menuItem: item})
                  )}
              </Menu>
              <div className="brand" style={{alignSelf:"center"}}>
                  <img src={logo} alt="" />
              </div>
          </S.SidenavLogo>

      </S.SidenavInner>
  );
}

export default Sidenav;

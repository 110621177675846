import * as S from "./Loading.styled";
import {Spin} from "antd";
import logo from "../../assets/imgs/logo.png";

const Loading = () => {

    return (
        <S.MainBox>
            <S.Image src={logo} alt=""/>
            <Spin/>
        </S.MainBox>
    )
}
export default Loading
import styled from 'styled-components';

export const UserInfoBox = styled.div`
  display: flex;
`
export const UserInfoUsernameBox = styled.div`
  margin-left: 10px;
  color: #484848;
`
export const UserInfoUsername = styled.div`
  
`
export const UserInfoRole = styled.div`
  font-size: 10px;
  font-weight: bold;
`

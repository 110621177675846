import {Button, Col, Drawer, Form, Input, notification, Popconfirm, Radio, Row, Select, Space, Tag} from "antd";
import {useEffect, useState} from "react";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {
    API_addAdmin, API_deleteAdmin,
    API_editAdmin, API_getAdmins, API_getRegions,
} from "../../../../services/api/apiCalls";
import {useResponsive} from "../../../../hooks/useResponsive";
import useApi from "../../../../hooks/useApi";

export default function AdministratorzyForm({onClose,onSubmit, admin, visible, formType}){
    const [form] = Form.useForm();
    const {isTablet} = useResponsive();
    const [loading, setLoading] = useState(false);
    const [loadingRemove, setLoadingRemove] = useState(false);
    const [changePassword, setChangePassword] = useState(false);
    const [loginExists, setLoginExists] = useState(null);
    const getRegionsApi = useApi(API_getRegions,[], "Nie udało się pobrać regionów.")
    const [regionsParsed, setRegionsParsed] = useState([]);

    useEffect(() => {
        if(visible){
            form.resetFields()
            setLoginExists(null);
            setChangePassword(false);
            getRegionsApi.call();
        }
    },[visible])

    useEffect(() => {
        let regionsOutput = [];
        if(!Array.isArray(getRegionsApi?.data)) return;
        getRegionsApi.data.forEach(region => {
            regionsOutput.push({
                value: region.id.toString(),
                label: region.name
            })
        })
        setRegionsParsed(regionsOutput)
    },[JSON.stringify(getRegionsApi.data)])

    const handleSubmit = (content) => {
        setLoading(true);
        if(formType === "add"){
            API_addAdmin({...content, email: ""}).then(() => {
                notification.success({message: "Poprawnie dodano nowego administratora."})
                onSubmit();
            }).catch((err) => {
                if(err?.response?.data?.code === "LOGIN_EXISTS"){
                    notification.error({message: "Podany login już istnieje."})
                    setLoginExists(content.login);
                }else{
                    notification.error({message: "Wystąpił błąd podczas dodawania."})
                }
                console.log(err)
            }).finally(() => setLoading(false))
        }else{
            API_editAdmin({...content, email: "", id: admin.id, isSuperAdmin: undefined}).then(() => {
                notification.success({message: "Zmiany zostały zapisane."})
                onSubmit();
            }).catch((err) => {
                if(err?.response?.data?.code === "LOGIN_EXISTS"){
                    notification.error({message: "Podany login już istnieje."})
                    setLoginExists(content.login);
                }else{
                    notification.error({message: "Wystąpił błąd podczas edycji."})
                }
                console.log(err)
            }).finally(() => setLoading(false))
        }

    }
    const handleClose = () => {
        onClose();
    }
    const handleRemove = () => {
        setLoadingRemove(true);
        API_deleteAdmin(admin.id).then(() => {
            notification.success({message: "Poprawnie usunięto administratora."})
            onSubmit();
        }).catch(() => {
            notification.error({message: "Nie udało się usunąć regionu."})
        }).finally(() => setLoadingRemove(false))
    }

    return (
        <Drawer
            title={formType === "add" ? "Dodaj nowego administratora" : "Edytuj dane administratora"}
            width={isTablet ? 480 : null}
            onClose={handleClose}
            placement="right"
            open={visible}
            extra={
                <Space>
                    <Button onClick={handleClose}>Anuluj</Button>
                    <Button loading={loading} onClick={() => form.submit()} type="primary">
                        {formType === "add" ? "Dodaj" : "Zapisz"}
                    </Button>
                </Space>
            }
        >
            <Form
                onFinish={handleSubmit}
                form={form}
                layout="vertical"
                initialValues={{...admin, isSuperAdmin: false}}
                preserve={false}
            >
                <Form.Item
                    name="name"
                    label="Imię"
                    rules={[
                        {
                            required: true,
                            message: 'Wprowadź imię kierowcy.',
                        },
                    ]}
                >
                    <Input placeholder="Wprowadź imię kierowcy." />
                </Form.Item>
                <Form.Item
                    name="lastname"
                    label="Nazwisko"
                    rules={[
                        {
                            required: true,
                            message: 'Wprowadź nazwisko kierowcy.',
                        },
                    ]}
                >
                    <Input placeholder="Wprowadź nazwisko kierowcy." />
                </Form.Item>
                <Form.Item
                    name="phone"
                    label="Numer telefonu"
                    rules={[
                        {
                            required: true,
                            message: 'Wprowadź numer telefonu administratora.',
                        },
                    ]}
                >
                    <Input placeholder="Wprowadź numer telefonu administratora." />
                </Form.Item>
{/*                <Form.Item
                    name="email"
                    label="Adres email"
                    rules={[
                        {
                            required: true,
                            message: 'Wprowadź adres email administratora.',
                        },
                    ]}
                >
                    <Input placeholder="Wprowadź adres email administratora." />
                </Form.Item>*/}
                <Form.Item
                    name="isSuperAdmin"
                    label="Rola"
                    rules={[
                        {
                            required: true,
                            message: 'Wprowadź rolę administratora.',
                        },
                    ]}
                >
                    <Radio.Group>
                        <Radio value={false}><Tag color="blue">Administrator</Tag></Radio>
                    </Radio.Group>

                </Form.Item>
                <Form.Item
                    name="districts"
                    label="Regiony"
                    rules={[
                        {
                            required: true,
                            message: 'Wybierz przynajmniej jeden obsługiwany region.',
                        },
                    ]}
                >
                    <Select
                        mode="tags"
                        style={{
                            width: '100%',
                        }}
                        labelInValue
                        options={regionsParsed}
                    />
                </Form.Item>
                <h3>Dane logowania</h3>
                <Form.Item
                    name="login"
                    label="Login"
                    hasFeedback
                    validateStatus={loading? "validating" : loginExists !== null ? "error" : ""}
                    rules={[
                        {
                            required: true,
                            message: 'Wprowadź login kierowcy.',
                        },
                    ]}
                >
                    <Input placeholder="Wprowadź login kierowcy." />
                </Form.Item>
                <Form.Item
                    autoComplete="none"
                    name="password"
                    label="Hasło"
                    rules={[
                        {
                            required: formType === "add" || (formType === "edit" && changePassword),
                            message: 'Wprowadź hasło administratora.',
                        },
                    ]}
                >
                    {formType === "add" &&
                        <Input.Password autoComplete="none" size="small" placeholder="Wprowadź hasło administratora." />
                    }
                    {formType === "edit" && !changePassword &&
                        <Row align="middle">
                            <Col span={16}>
                                <Input.Password autoComplete="none" value="someVeryStrongPassword"  disabled size="small" placeholder="Wprowadź nowe hasło administratora." />
                            </Col>
                            <Col offset={1}>
                                <Button onClick={() => setChangePassword(true)}>Zmień hasło</Button>
                            </Col>
                        </Row>
                    }
                    {formType === "edit" && changePassword &&
                        <Row align="middle">
                            <Col span={16}>
                                <Input.Password autoComplete="off" size="small" placeholder="Wprowadź nowe hasło administratora." />
                            </Col>
                            <Col offset={1}>
                                <Button danger onClick={() => setChangePassword(false)}>Anuluj</Button>
                            </Col>
                        </Row>
                    }
                </Form.Item>
            </Form>
            {formType === "edit" ?
                <Popconfirm
                    title="Usuwanie administratora."
                    onConfirm={handleRemove}
                    description="Czy na pewno chcesz usunąć administratora?"
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                >
                    <Button loading={loadingRemove} danger>Usuń administratora</Button>
                </Popconfirm>
                : null
            }
        </Drawer>

    )
}
import * as XLSX from "xlsx";
import moment from "moment/moment";

const getStreetFromAddress = (addressString) => {
    const addressArray = addressString.split(",")
    return addressArray[0].trim();

}
const getCityFromAddress = (addressString) => {
    const addressArray = addressString.split(",")
    return addressArray.length >= 2 ? addressArray[addressArray.length - 1].trim() : ""
}
export const excelExportReport = (zleceniaData, fileName) => {
    const rows = zleceniaData.map(zlecenie => {
        return {
            "Id zlecenia": zlecenie.number,
            "Data utworzenia": zlecenie.addTime ? moment(zlecenie.addTime).format("DD.MM.YYYY HH:mm") : "",
            "Data realizacji": zlecenie.realizationTime ? moment(zlecenie.realizationTime).format("DD.MM.YYYY HH:mm") : "",
            "Kwota": zlecenie.amount,
            "Sposób płatności": zlecenie.payMethod === "cash" ? "Gotówka" : zlecenie.payMethod === "card" ? "Karta" : "",
            "ID Restauracji": zlecenie.restaurant.id || "",
            "Restauracja": zlecenie.restaurant.name || "",
            "ID Kierowcy": zlecenie.driver.id || "",
            "Kierowca": zlecenie.driver.id ? zlecenie.driver.name + " " + zlecenie.driver.lastname : "",
            "Adres dostawy": getStreetFromAddress(zlecenie.address),
            "Miejscowość": getCityFromAddress(zlecenie.address),
            "Status": zlecenie.status,
            "Komentarz": zlecenie.comment || ""
        }
    })

    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    worksheet['!cols'] = [
        { wch: 10 }, //id zlecenia
        { wch: 20 }, // data utworzenia
        { wch: 20 }, // data realizacji
        { wch: 10 }, // kwota
        { wch: 10 }, // sposob platnosci
        { wch: 6 }, // id restauracji
        { wch: 20 }, // restauracja
        { wch: 6 }, // id kierowcy
        { wch: 20 }, // kierowca
        { wch: 30 }, // adres dostawy
        { wch: 20 }, // miejscowosc
        { wch: 15 } // status
    ];

    for(let rowCount = 2; rowCount <= rows.length + 1; rowCount++){
        if(worksheet["D"+rowCount]) worksheet["D"+rowCount].z="#,##0.00 zł";
    }
    XLSX.utils.book_append_sheet(workbook, worksheet, "Zlecenia");
    worksheet["!autofilter"] = { ref: "A1:L1" };

    /* create an XLSX file and try to save to Presidents.xlsx */
    XLSX.writeFile(workbook, fileName+".xlsx", { compression: true });

}
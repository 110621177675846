function addThousandSeparator(amount) {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}
const formatAmount = (amount, thousandSeparator= true) => {
    // Jeśli nie przesłano żadnej wartości ustaw 0
    if(amount === undefined) return "---"

    const amountFormatted =  new Intl.NumberFormat('pl-PL', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(amount)

    if(thousandSeparator)
        return addThousandSeparator(amountFormatted)
    else
        return amountFormatted
}
export default formatAmount
import {Tag} from "antd";
import capitalizeFirstLetter from "../../../../functions/capitalizeFirstLetter";

export default function StatusBadge({status}) {
    const color = () => {
        switch (status.toLowerCase()){
            case "nowe": return "blue"
            case "odebrano": return "gold"
            case "zrealizowano": return "green"
            case "usunieto": return "red"
            default: return "gray"
        }
    }
    const statusFormatted = () => {
        switch (status.toLowerCase()){
            case "usunieto" : return "Usunięto"
            default: return capitalizeFirstLetter(status)
        }
    }
    return <Tag color={color()}>{statusFormatted()}</Tag>
}
import {Badge, Button, Card, Spin, Table} from "antd";
import {useEffect, useState} from "react";
import {EnvironmentOutlined, PlusOutlined} from "@ant-design/icons";
import useApi from "../../../hooks/useApi";
import {API_getRegions} from "../../../services/api/apiCalls";
import OddzialyForm from "./OddzialyForm/OddzialyForm";


function Oddzialy ()  {
    const [isFormVisible, setFormVisible] = useState(false);
    const [formType, setFormType] = useState("add");
    const [isRegionInfoVisible, setRegionInfoVisible] = useState(false);
    const [selectedRegion, setSelectedRegion] = useState(null);
    const getRegionsApi = useApi(API_getRegions,[], "Nie udało się pobrać regionów.")
    const columns = [
        {
            title: "Miasto",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Punkt centralny",
            dataIndex: "center",
            key: "center",
            render: (address) => <div><Button style={{marginRight:10}} type="primary" size="small" onClick={() => window.open('https://www.google.com/maps?q='+address, '_blank', 'noreferrer')} icon={<EnvironmentOutlined />}></Button>{address}</div>

        },
        {
            title: "Promień",
            key: "radius",
            dataIndex: "radius",
            render: (radius) => <div>{radius ? parseInt(radius).toLocaleString() : ""} m</div>

        },
        {
            title: "AKCJE",
            key: "akcje",
            align: "center",
            render: (record) => {
                return <div>
                    <Button size="small" onClick={() => handleOpenEditForm(record)}>Edytuj</Button>
                </div>
            }
        }
    ];

    useEffect(() => {
        getRegionsApi.call();
    }, [])


    const handleOpenOrder = (region) => {
        setRegionInfoVisible(true);
        setSelectedRegion(region);
    }
    const handleCloseOrder = () => {
        setRegionInfoVisible(false);
    }
    const handleOpenAddForm = () => {
        setFormVisible(true);
        setFormType("add");
        setSelectedRegion(null);
    }
    const handleOpenEditForm = (region) => {
        setFormVisible(true);
        setFormType("edit");
        setSelectedRegion(region)
    }
    const handleCloseForm = () => {
        setFormVisible(false);
    }
    const handleSubmit = () => {
        setFormVisible(false);
        getRegionsApi.call();
    }
    return (
        <>
            <Card
                bordered={false}
                className="criclebox tablespace mb-24"
                title={
                    <>
                        Oddziały
                        <Badge overflowCount={9999} offset={[5,-5]} count={getRegionsApi?.data.length} color={"#1677ff"}></Badge>
                    </>
                }
                extra={
                    <>
                        <Spin spinning={getRegionsApi.silentLoading} style={{marginRight:10}}/>
                        <Button onClick={handleOpenAddForm} icon={<PlusOutlined />}>Dodaj oddział</Button>
                    </>
                }
            >
                <div className="table-responsive">
                    <Table
                        rowKey={(record) => record.id+"region"}
                        columns={columns}
                        loading={getRegionsApi.loading}
                        dataSource={getRegionsApi.data}
                        pagination={false}
                        className="ant-border-space"
                    />
                </div>
            </Card>
            <OddzialyForm onClose={handleCloseForm} onSubmit={handleSubmit} region={selectedRegion} visible={isFormVisible} formType={formType}/>
        </>

    )
}
export default Oddzialy
const googlePlacesApiParser = (address_components) => {
    let ShouldBeComponent = {
        number: ["street_number"],
        //postal_code: ["postal_code"],
        street: ["street_address", "route"],
        city: [
            "locality",
            "sublocality",
            "sublocality_level_1",
            "sublocality_level_2",
            "sublocality_level_3",
            "sublocality_level_4"
        ],
        //country: ["country"]
    };

    let address = {
        number: "",
        //postal_code: "",
        street: "",
        city: "",
        //country: ""
    };
    address_components.forEach(component => {
        for (let shouldBe in ShouldBeComponent) {
            if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
                if (shouldBe === "country") {
                    address[shouldBe] = component.short_name;
                } else {
                    address[shouldBe] = component.long_name;
                }
            }
        }
    });
    return address;
}
export default googlePlacesApiParser

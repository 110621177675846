import FilterCard from "../FilterCard/FilterCard";
import {Select} from "antd";
import {mdiListStatus} from "@mdi/js";
import StatusBadge from "../../../StatusBadge/StatusBadge";
const filterRender = (props) => {
    const {value} = props;
    return <StatusBadge status={value}/>
}
const StatusFilter = ({onChange,defaultFilters= {status: []}}) => {
    const handleChange = (value) => {
        onChange({status: value})
    }

    return (
        <FilterCard title="Status" iconPath={mdiListStatus}>
            <Select
                style={{width:260}}
                tagRender={filterRender}
                allowClear
                mode="multiple"
                placeholder="Wybierz status"
                optionFilterProp="children"
                onChange={handleChange}
                defaultValue={defaultFilters.status}
                options={[
                    {value: 'nowe', label: "Nowe"},
                    {value: 'odebrano', label: "Odebrano"},
                    {value: 'zrealizowano', label: "Zrealizowano"},
                    {value: 'usunieto', label: "Usunięto"},
                ]}
            />
        </FilterCard>
    )
}
export default StatusFilter
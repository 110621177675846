import { createAction, createSlice } from '@reduxjs/toolkit';
import {
    deleteRefreshToken, deleteSessionTime,
    persistRefreshToken,
    persistSessionTime,
    persistUserId,
    readUserId
} from "../../services/localStorage.service";

const initialState = {
    data: null,
    token: null
}

export const setUser = createAction(
    'user/setUser',
    (userData,token, refreshToken) => {
        // if previous userid is different clear localstorage
        if(userData.id !== readUserId()){
            localStorage.clear()
        }
        persistUserId(userData.id)
        persistRefreshToken(refreshToken);
    return {
        payload: {userData,token}
    };
});
export const removeUser = createAction(
    'user/removeUser',
    () => {
        deleteRefreshToken();
        deleteSessionTime();
        return {
            payload: true
        };
    });

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(setUser, (state, action) => {
            state.data = action.payload.userData;
            state.token = action.payload.token;
        })
            .addCase(removeUser,(state) => {
                state.data = initialState
            })
    },
});

export default userSlice.reducer;

import { Layout} from "antd";

function Footer() {
  const { Footer: AntFooter } = Layout;

  return (
    <AntFooter style={{ background: "#fafafa" }}>

    </AntFooter>
  );
}

export default Footer;

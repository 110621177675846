import CryptoJS from 'crypto-js';
export const setItemToSecureLocalStorage = (name, data) => {
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_SECRET_KEY).toString();
    localStorage.setItem(name, encrypted);
}
export const getItemFromSecureLocalStorage = (name) => {
    const encrypted = localStorage.getItem(name);
    if(encrypted){
        const decrypted = CryptoJS.AES.decrypt(encrypted, process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8);
        return JSON.parse(decrypted);
    } else return null;
}
export const persistSessionTime = (time = new Date().toISOString()) => {
    setItemToSecureLocalStorage("sessionTime", time)
}
export const readSessionTime = () => {
    return getItemFromSecureLocalStorage("sessionTime");
}
export const deleteSessionTime = () => {
    localStorage.removeItem("sessionTime");
}
export const persistRefreshToken = (token) => {
    setItemToSecureLocalStorage('accessToken', token);
};

export const readRefreshToken = () => {
    return getItemFromSecureLocalStorage('accessToken') || null;
};
export const persistUserId = (userId) => {
    setItemToSecureLocalStorage('userId', userId)
}
export const readUserId = () => {
    return getItemFromSecureLocalStorage('userId') || null;
}
export const persistOrdersSettings = (settings) => {
    setItemToSecureLocalStorage('orders_settings', JSON.stringify(settings))
}
export const readOrdersSettings = () => {
    return JSON.parse(getItemFromSecureLocalStorage('orders_settings') || "{}")
}

export const readCurrentDistrictId = () => {
    const localStr = getItemFromSecureLocalStorage('currentDistrictId');
    return localStr ? JSON.parse(localStr) : null;
}

export const persistCurrentDistrictId = (currentDistrictId) => {
    if(currentDistrictId)
        setItemToSecureLocalStorage('currentDistrict', JSON.stringify(currentDistrictId));
};

export const deleteRefreshToken = ()=> localStorage.removeItem('accessToken');


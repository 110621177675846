import styled from "styled-components";

export const MainCard = styled.div`
  padding: 10px;
 // border: 1px solid gainsboro;
  border-radius: 10px;
  position: relative;
  margin:20px 0 5px 0;
`
export const Title = styled.div`
  position: absolute;
  padding: 0 2px;
  left: 10px;
  top: -6px;
  line-height: 10px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`
import * as S from "./DriverInfo.styled";
import stringToHslColor from "../../../../functions/stringToHslColor";
import aliasFromUsername from "../../../../functions/aliasFromUsername";
import {Avatar} from "antd";
export default function DriverInfo({driver, onClick, fontSize=null}) {
    const driverName = driver.name + " " + driver.lastname
    return(
        <S.DriverInfoBoxOuter>
            <S.DriverInfoBox onClick={onClick}>
                <div><Avatar size={25} style={{backgroundColor: stringToHslColor(driverName)}}>{aliasFromUsername(driverName)}</Avatar></div>

                <S.DriverInfoName style={{fontSize: fontSize ?? null}}>{driverName}</S.DriverInfoName>
            </S.DriverInfoBox>
        </S.DriverInfoBoxOuter>

    )
}
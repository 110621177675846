import {Navigate, Route, Routes} from "react-router-dom";
import ProtectedLayout from "./components/layout/ProtectedLayout";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import Kierowcy from "./pages/Kierowcy/Kierowcy";
import Restauracje from "./pages/Restauracje/Restauracje";
import Zlecenia from "./pages/Zlecenia/Zlecenia";
import { useEffect, useState} from "react";
import {API_checkToken} from "./services/api/apiCalls";
import {useDispatch, useSelector} from "react-redux";
import {removeUser, setUser} from "./redux/slices/userSlice";
import {setCurrentDistrictId, setDistricts} from "./redux/slices/districtsSlice";
import Loading from "./pages/Loading/Loading";
import dayjs from 'dayjs'
import 'dayjs/locale/pl'
import moment from "moment";
import Login from "./pages/Login/Login";
import Logout from "./pages/Login/Logout";
import {readCurrentDistrictId, readRefreshToken} from "./services/localStorage.service";
import ProtectedLayoutSuperAdmin from "./components/layout/ProtectedLayoutSuperAdmin";
import Oddzialy from "./pages/Superadmin/Oddzialy/Oddzialy";
import apiService from "./services/api/api.service";
import Administratorzy from "./pages/Superadmin/Administratorzy/Administratorzy";
import 'moment/locale/pl'
import isSessionValid from "./functions/isSessionValid";
moment.locale("pl");
dayjs.locale("pl");

function App() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const currentDistrictId = useSelector(state => state.districts.currentDistrictId);
  const userToken = useSelector(state => state.user.token);
  // watch token and current district change
    useEffect(() => {
        apiService.setDistrict(currentDistrictId)
    },[currentDistrictId])
    useEffect(() => {
        apiService.setToken(userToken);
    },[userToken])

  const checkToken = () => {
      setLoading(true);
      API_checkToken()
          .then(({data}) => {
              dispatch(setUser(data.userInfo, data.userToken, data.refreshToken))
              dispatch(setDistricts(data.districts));
          })
          .catch(() => {
              dispatch(removeUser());
          })
          .finally(() => {
              setLoading(false);
          })
  }
  useEffect(() => {
      if(!isSessionValid()){
            dispatch(removeUser());
            setLoading(false);
      }
      else if(readRefreshToken()){
          checkToken();
      }
      else setLoading(false)

      console.log('version ' + process.env.REACT_APP_VERSION);
      if(process.env.REACT_APP_ENV === "dev") console.log("dev mode, api url: " + process.env.REACT_APP_API_URL);

  }, [])
  return (
    <div className="App">

        {loading ?
            <Loading/>
            :
            <Routes>
                <Route path="/" element={<ProtectedLayout/>}>
                    <Route exact path="" element={<Navigate to="/kierowcy"/>} />
                    {/*<Route exact path="home" element={<Home/>} />*/}
                    <Route exact path="kierowcy" element={<Kierowcy/>} />
                    <Route exact path="restauracje" element={<Restauracje/>} />
                    <Route exact path="zlecenia" element={<Zlecenia/>} />
                    <Route path="" element={<ProtectedLayoutSuperAdmin/>}>
                        <Route exact path="oddzialy" element={<Oddzialy/>} />
                        <Route exact path="administratorzy" element={<Administratorzy/>} />
                    </Route>
                </Route>
                <Route path="/login" element={<Login/>} />
                <Route path="/logout" element={<Logout/>} />
            </Routes>

        }
    </div>
  );
}

export default App;

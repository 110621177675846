import {Badge, Button, Card, Spin, Table, Tag} from "antd";
import {useEffect, useState} from "react";
import {PlusOutlined} from "@ant-design/icons";
import useApi from "../../../hooks/useApi";
import {API_getAdmins, API_getRegions} from "../../../services/api/apiCalls";
import AdministratorzyForm from "./AdministratorzyForm/AdministratorzyForm";
import formatPhoneNumber from "../../../functions/formatPhoneNumber";



function Administratorzy ()  {
    const [isFormVisible, setFormVisible] = useState(false);
    const [formType, setFormType] = useState("add");
    const [isRegionInfoVisible, setAdminsInfoVisible] = useState(false);
    const [selectedAdmin, setSelectedAdmin] = useState(null);
    const getAdminsApi = useApi(API_getAdmins,[], "Nie udało się pobrać administratorów.")
    const columns = [
        {
            title: "IMIĘ",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "NAZWISKO",
            dataIndex: "lastname",
            key: "lastname",
        },
        {
            title: "LOGIN",
            dataIndex: "login",
            key: "login",
        },
        {
            title: "ROLA",
            dataIndex: "isSuperAdmin",
            key: "isSuperAdmin",
            render: (isSuperAdmin) => isSuperAdmin ? <Tag color="blue-inverse" >Superadmin</Tag> : <Tag color="blue">Administrator</Tag>

        },
        {
            title: "TELEFON",
            key: "phone",
            dataIndex: "phone",
            render: (phone) => formatPhoneNumber(phone)
        },
        {
            title: "REGIONY",
            key: "districts",
            render: (data) => data.isSuperAdmin ? <Tag color="grey">Wszystkie</Tag> : <div>{data.districts.map(district => <div key={district.value+"tag"+data.id+""}><Tag>{district.label}</Tag></div>)}</div>
        },
        {
            title: "AKCJE",
            key: "akcje",
            align: "center",
            render: (record) => {
                return <div>
                    <Button size="small" onClick={() => handleOpenEditForm(record)}>Edytuj</Button>
                </div>
            }
        }
    ];

    useEffect(() => {
        getAdminsApi.call();
    }, [])


    const handleOpenOrder = (admin) => {
        setAdminsInfoVisible(true);
        setSelectedAdmin(admin);
    }
    const handleCloseOrder = () => {
        setAdminsInfoVisible(false);
    }
    const handleOpenAddForm = () => {
        setFormVisible(true);
        setFormType("add");
        setSelectedAdmin(null);
    }
    const handleOpenEditForm = (admin) => {
        setFormVisible(true);
        setFormType("edit");
        setSelectedAdmin(admin)
    }
    const handleCloseForm = () => {
        setFormVisible(false);
    }
    const handleSubmit = () => {
        setFormVisible(false);
        getAdminsApi.call();
    }
    return (
        <>
            <Card
                bordered={false}
                className="criclebox tablespace mb-24"
                title={
                    <>
                        Administratorzy oddziałów
                        <Badge overflowCount={9999} offset={[5,-5]} count={getAdminsApi?.data.length} color={"#1677ff"}></Badge>
                    </>
                }
                extra={
                    <>
                        <Spin spinning={getAdminsApi.silentLoading} style={{marginRight:10}}/>
                        <Button onClick={handleOpenAddForm} icon={<PlusOutlined />}>Dodaj administratora</Button>
                    </>
                }
            >
                <div className="table-responsive">
                    <Table
                        rowKey={(record) => record.id+"admin"}
                        columns={columns}
                        loading={getAdminsApi.loading}
                        dataSource={getAdminsApi.data}
                        pagination={false}
                        className="ant-border-space"
                    />
                </div>
            </Card>
            <AdministratorzyForm onClose={handleCloseForm} onSubmit={handleSubmit} admin={selectedAdmin} visible={isFormVisible} formType={formType}/>
        </>

    )
}
export default Administratorzy
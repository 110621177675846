import styled from "styled-components";

export const LoginOuter = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const LoginLogo = styled.img`
  width: 150px;
  margin: -10px 0;
`
import styled from "styled-components";

export const MainBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
`
export const Image = styled.img`
  height: 100px;
  margin-bottom: 10px;
`
import {Button, Drawer, Form, Input, notification, Popconfirm, Row, Space} from "antd";
import {useEffect, useState} from "react";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {API_addRegion, API_deleteRegion, API_editRegion} from "../../../../services/api/apiCalls";
import {useResponsive} from "../../../../hooks/useResponsive";
import {APIProvider, Map} from "@vis.gl/react-google-maps";
import OddzialyMap from "./OddzialyMap";

export default function OddzialyForm({onClose,onSubmit, region, visible, formType}){
    const [form] = Form.useForm();
    const {isTablet} = useResponsive();
    const [loading, setLoading] = useState(false);
    const [loadingRemove, setLoadingRemove] = useState(false);
    const [centerCords, setCenterCords] = useState(region?.center)
    useEffect(() => {
        if(visible){
            form.resetFields()
            setCenterCords(region?.center)
        }else setCenterCords(null)
    },[visible])
    const handleSubmit = (content) => {
        setLoading(true);
        if(formType === "add"){
            API_addRegion({...content}).then(() => {
                notification.success({message: "Poprawnie dodano nowy region."})
                onSubmit();
            }).catch((err) => {
                notification.error({message: "Nie udało się dodać oddziału."})
                console.log(err)
            }).finally(() => setLoading(false))
        }else{
            API_editRegion({...content, id: region.id}).then(() => {
                notification.success({message: "Zmiany zostały zapisane."})
                onSubmit();
            }).catch((err) => {
                notification.error({message: "Wystąpił błąd podczas edycji danych."})
                console.log(err)
            }).finally(() => setLoading(false))
        }

    }
    const handleClose = () => {
        onClose();
    }
    const handleRemove = () => {
        setLoadingRemove(true);
        API_deleteRegion(region.id).then(() => {
            notification.success({message: "Poprawnie usunięto region."})
            onSubmit();
        }).catch(() => {
            notification.error({message: "Nie udało się usunąć regionu."})
        }).finally(() => setLoadingRemove(false))
    }
    const validateCoordinates = (rule, value) => {
        if (value && !/^\s*-?\d+(\.\d+)?\s*,\s*-?\d+(\.\d+)?\s*$/.test(value)) {
            return Promise.reject('Wprowadź poprawne współrzędne w formie "0.00, 0.00".');
        }
        return Promise.resolve();
    };
    const validateRadius = (rule, value) => {
        if(value && !/^[1-9]\d*$/.test(value)){
            return Promise.reject('Promień powinien być liczbą całkowitą.');
        }
        return Promise.resolve();
    }
    return (
        <Drawer
            title={formType === "add" ? "Dodaj nowy region" : "Edytuj dane regionu"}
            width={isTablet ? 450 : null}
            onClose={handleClose}
            placement="right"
            open={visible}
            extra={
                <Space>
                    <Button onClick={handleClose}>Anuluj</Button>
                    <Button loading={loading} onClick={() => form.submit()} type="primary">
                        {formType === "add" ? "Dodaj" : "Zapisz"}
                    </Button>
                </Space>
            }
        >
            <Form
                onFinish={handleSubmit}
                form={form}
                layout="vertical"
                initialValues={region}
                preserve={false}
            >
                <Form.Item
                    name="name"
                    label="Miasto"
                    rules={[
                        {
                            required: true,
                            message: 'Wprowadź nazwę regionu.',
                        },
                    ]}
                >
                    <Input autocomplete="off" placeholder="Wprowadź nazwę regionu." />
                </Form.Item>
                <Form.Item
                    onBlur={(value)=>setCenterCords(form.getFieldValue("center"))}
                    name="center"
                    label="Punkt centralny"
                    rules={[
                        {
                            required: true,
                            message: 'Wprowadź koordynaty punktu centralnego.',
                        },
                        { validator: validateCoordinates }
                    ]}
                >
                    <Input autocomplete="off" placeholder="Wprowadź koordynaty punktu centralnego." />
                </Form.Item>
                <OddzialyMap formVisible={visible} defaultMarkerPosition={centerCords} onPlaceSelect={(center) => {form.setFieldValue("center", center); setCenterCords(center)}}/>
                <Form.Item
                    name="radius"
                    label="Promień w metrach"
                    rules={[
                        {
                            required: true,
                            message: 'Wprowadź promień dostępności dostaw.',
                        },
                        {validator: validateRadius}
                    ]}
                >
                    <Input type="number" autocomplete="off" placeholder="Wprowadź promień dostępności dostaw." />
                </Form.Item>

            </Form>
            {formType === "edit" ?
                <Popconfirm
                    title="Usuwanie regionu."
                    onConfirm={handleRemove}
                    description="Czy na pewno chcesz usunąć region?"
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                >
                    <Button loading={loadingRemove} danger>Usuń region</Button>
                </Popconfirm>
                : null
            }
        </Drawer>

    )
}
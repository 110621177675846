// ALL CALLS SCHEME
// /api/admin/(e.g. drivers)

import {readRefreshToken} from "../localStorage.service";
import apiService from "./api.service";
import qs from "qs";


export const API_login = (login, password) => {
    return apiService.post("/admin/login",{
        login,password
    })
}
// checkToken
export const API_checkToken = () => {
    return apiService.get("/admin/login/sprawdz_token",{headers:{refreshToken: readRefreshToken()}})
}
const userResponse = {
    userInfo: {
        id: 1,
        username: "Marcin Połczyński",
        role: 'administrator'
    },
    refreshToken:"asdaojpf0sd-gfdjgs-d0fgd-f-g-df-gdf",
    accessToken:"12oij31mk2lemoie231j4324532423-jrle",
    districts: [
        { value: "1", label: 'Ostrów Wielkopolski' },
        { value: "2" , label: 'Szczecin' },
        { value: "3", label: 'Kalisz' },
    ]
}
//drivers

// GET .../drivers/{id_of_region}
// --------------------- get all drivers ----------------------
export const API_getDrivers = () => {
    return apiService.get('/admin/kierowcy/'+apiService.district)
}
const driversResponse = [
    {
        id: 1,
        name: "Marcin",
        lastname: "Połczyński",
        login: "kier",
        phone: "+48123123123",
        status: 1,  // 0 - not working | 1 - working
        actualOrder: 1, // orderId if exists, 0 or null if not
    }
]
// ------------------------------------------------------------------

// GET .../driver/{driverId}
// ------------------ get driver by driverId ------------------
export const API_getDriver = (driverId) => {
    return apiService.get('/admin/kierowca/'+driverId)
}
// GET .../drivers_position/{driverId}
// ------------- get drivers position by region -----------
export const API_getDriversPosition = () => {
    return apiService.get('/admin/kierowcy/pozycja/'+apiService.district)
}
const driversPositionResponse = [{
    id: 1,
    lat: 51.12343254,
    lon: 17.2341233
}]
// GET .../driver_position/{driverId}
// ------------- get driver position by driver id -----------
export const API_getDriverPosition = (driverId) => {
    return apiService.get('/admin/kierowca/pozycja/'+driverId)
}
const driverPositionResponse = {
    id: 1,
    lat: 51.12343254,
    lon: 17.2341233
}
// ----------------------------------------------------------------

export const API_addDriver = (driver) => {
    return apiService.post('/admin/kierowca/dodaj',driver)
}
export const API_editDriver = (driver) => {
    return apiService.post('/admin/kierowca/edytuj',driver)
}
export const API_deleteDriver = (driverId) => {
    return apiService.delete('/admin/kierowca/usun/'+driverId);
}

// Restaurants
// GET .../restaurants/{id_of_region}
// --------------------- get all restaurants ----------------------
export const API_getRestaurants = () => {
    return apiService.get('/admin/restauracje/'+apiService.district)
}
const restaurantsResponse = [
    {
        name: "Koku Sushi",
        address: "Rynek 24, Ostrów Wielkopolski",
        login: "koku123",
        phone: "+48124556998",
    },
    {
        name: "Pizzeria flamingo",
        address: "Wolności 7B, Ostrów Wielkopolski",
        login: "koku123",
        phone: "+48124556998",
    },
]
export const API_getRestaurant = (idOfRestaurant) => {
    return apiService.get('/admin/restauracja/'+idOfRestaurant)
}
export const API_addRestaurant = (restaurant) => {
    return apiService.post('/admin/restauracja/dodaj',restaurant)
}
export const API_editRestaurant = (restaurant) => {
    return apiService.post('/admin/restauracja/edytuj',restaurant)
}
export const API_deleteRestaurant = (restaurantId) => {
    return apiService.delete('/admin/restauracja/usun/'+restaurantId);
}




// GET .../orders/{id_of_region}
// --------------------- get all orders ----------------------

export const API_getOrders = (queryObject) => {
    const queryString = qs.stringify(queryObject);
    if(process.env.REACT_APP_ENV === 'dev'){
        console.log(queryObject)
        console.log(queryString)
    }
    return apiService.get('/admin/zamowienia/wszystkie/' + apiService.district + (queryString ? "?"+queryString : ""))
}
const ordersResponse = {
    current: 1,
    total: 100,
    data: [
        {
            number: 123,
            restaurant: {
                id: 2,
                name: "Koku Sushi"
            },
            address: "Rynek 24, Ostrów Wielkopolski",
            driver: {
                id: 3,
                name: "Marcin Połczyński"
            },
            pickupTime: "2022-02-23T15:22:00",
            amount: 22.55,
            paymentType: "karta",
            status: "Odebrano"
        },
        {
            number: 124,
            restaurant: {
                id: 2,
                name: "Pizzeria flamingo"
            },
            address: "Skośna 2, Ostrów Wielkopolski",
            driver: {
                id: 3,
                name: "Adam Nowakowski"
            },
            pickupTime: "2022-02-23T15:22:00",
            amount: 120,
            paymentType: "gotówka",
            status: "Zakończone"
        },
        {
            number: 125,
            restaurant: {
                id: 2,
                name: "Pizzeria flamingo"
            },
            address: "Skośna 2, Ostrów Wielkopolski",
            driver: {
                id: 3,
                name: "Adam Nowakowski"
            },
            pickupTime: "2022-02-23T15:22:00",
            amount: 15,
            paymentType: "gotówka",
            status: "Nowe"
        },
        {
            number: 129,
            restaurant: {
                id: 2,
                name: "Pizzeria flamingo"
            },
            address: "Daleka 190, Ostrów Wielkopolski",
            driver: {
                id: 3,
                name: "Adam Nowakowski"
            },
            pickupTime: "2022-02-23T15:22:00",
            amount: 0,
            status: "Anulowano"
        },
    ]
}
// ------------------------------------------------------------------

// GET .../order/{orderId}
// --------------------- get all orders ----------------------

export const API_getOrder = (orderId) => {
    return fakeAPICall(orderResponse);
}
const orderResponse =     {
    number: 123,
    restaurant: {
        id: 2,
        name: "Koku Sushi"
    },
    address: "Rynek 24, Ostrów Wielkopolski",
    driver: {
        id: 3,
        name: "Marcin Połczyński"
    },
    pickupTime: "2022-02-23T15:22:00",
    amount: 22.55,
    paymentType: "karta",
    status: "Odebrano"
}
// ------------------------------------------------------------------
// ------------------------------------------------------------------
// GET .../regions
// --------------------- get all regions ----------------------
export const API_getRegions = () => {
    return apiService.get('/superAdmin/obszar/wszystkie')
}
const regionsResponse = [
    {
        id: 1,
        name: "Ostrów Wielkopolski",
        center: "51.6458616, 17.807235",
        radius: 20000
    },
    {
        id: 2,
        name: "Kalisz",
        center: "51.6458616, 17.807235",
        radius: 20000

    },
]
// GET .../region/{id}
// --------------------- get all regions ----------------------
export const API_getRegion = () => {
    return apiService.get('/superAdmin/obszar/'+apiService.district)
}
// ------------------------------------------------------------------
// POST .../region
// ------------------- add new region ----------------------
export const API_addRegion = (region) => {
    return apiService.post('/superAdmin/obszar/dodaj',region)
}
const addRegionRequest =     {
    name: "Ostrów Wielkopolski",
    center: "51.6458616, 17.807235",
    radius: 20000 // in meters
}
// ----------------------------------------------------------------
// PATCH .../region/{regionId}
// --------------- edit region by id ----------------------
//
export const API_editRegion = (region) => {
    return apiService.post('/superAdmin/obszar/edytuj',region)
}
const editRegionRequest = {
    // not full driver ONLY ITEMS TO CHANGE!
}
const editRegionResponse = {
    // FULL driver object
}
// ----------------------------------------------------------------
// DELETE .../region/{regionId}
export const API_deleteRegion = (regionId) => {
    return apiService.delete('/superAdmin/obszar/usun/'+regionId)
}
// ----------------------------------------------------------------
// ------------------------------------------------------------------
// GET .../admins
// --------------------- get all admins ----------------------
export const API_getAdmins = () => {
    return apiService.get("/superadmin/admin/wszyscy")
}
const adminsResponse = [
    {
        id: 1,
        name: "Marcin",
        lastname: "Połczyński",
        login: "admin",
        isSuperAdmin: true,
        phone: "+48123123123",
        email: "testowy@test.pl",
        districts:[
            {label: "Ostrów Wielkopolski", value: "1"},
            {label: "Kalisz", value: "2"}
        ]
    },
    {
        id: 2,
        name: "Adam",
        lastname: "Kowal",
        login: "kadam",
        isSuperAdmin: false,
        phone: "+48001222564",
        email: "kowala@test.pl",
        districts:[
            {label: "Ostrów Wielkopolski", value: "1"},
            {label: "Kalisz", value: "2"}
        ]
    },
    {
        id: 3,
        name: "Maurycy",
        lastname: "Adamski",
        login: "marycy",
        isSuperAdmin: false,
        phone: "+48159852654",
        email: "mcycy@test.pl",
        districts:[
            {label: "Kalisz", value: "2"}
        ]
    }
]
// ------------------------------------------------------------------
// POST .../admin
// ------------------- add new admin ----------------------
export const API_addAdmin = (admin) => {
    return apiService.post("/superadmin/admin/dodaj",admin)
}
const addAdminRequest =     {
    name: "Marcin",
    lastname: "Połczyński",
    login: "admin",
    isSuperAdmin: true,
    password: "jakiśtampassword",
    phone: "+48123123123",
    email: "testowy@test.pl",
    districts:[
        {label: "Ostrów Wielkopolski", value: "1"}
    ]
}
// ----------------------------------------------------------------
// PATCH .../admin/{adminId}
// --------------- edit admin by id ----------------------
//
export const API_editAdmin = (admin) => {
    return apiService.post("/superadmin/admin/edytuj",admin)
}
// ----------------------------------------------------------------
// DELETE .../admin/{adminId}
export const API_deleteAdmin = (adminId) => {
    return apiService.delete("/superadmin/admin/usun/"+adminId)
}
// ----------------------------------------------------------------
// ------------------------------------------------------------------



const fakeAPICall = (data) => {
    return new Promise(
        resolve => setTimeout(() => {resolve({data})}, 1000));
}
const formatStringToCords = (stringCords, defaultLat = 52.120086857731195, defaultLon = 19.4719645616484) => {
    // Podziel string na części używając przecinka jako separatora
    const parts = stringCords?.split(',');

    // Sprawdź, czy udało się uzyskać dwie części
    if (parts?.length === 2) {
        // Parsuj wartości na liczby
        const lat = parseFloat(parts[0]);
        const lon = parseFloat(parts[1]);

        // Sprawdź, czy udało się uzyskać liczby
        if (!isNaN(lat) && !isNaN(lon)) {
            // Zwróć obiekt zawierający szerokość i długość geograficzną
            return { lat, lon };
        } else {
            console.error('Błąd parsowania wartości szerokości i długości geograficznej.');
        }
    }

    // Zwróć null w przypadku błędu
    return {lat: defaultLat, lon: defaultLon};
}
export default formatStringToCords